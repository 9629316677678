<template>
  <article class="coupon grid-12" >
    <div class="col-sp-8 row-sp-12 textBox">
      <span style="display: block; padding-bottom: 3px; font-weight: bold; font-size: 1.2em; color: #2068ff" @click="copyToClipboard">#{{ coupon.code.toUpperCase() }}</span>
      <p v-if="coupon.copy">Text wurde in die Zwischenablage kopiert!</p>
      {{ coupon.title }}<br>
      {{ coupon.description }}<br>
      Hinweise: <br><span style="display: block; font-size: 0.6em; padding: 4px 0 8px 0;">{{ coupon.extra }}</span>
      gültig bis: {{ validUntil }}<br>
      gültig für:
      {{JSON.parse(this.coupon.data).drinks.type==='all'?JSON.parse(this.coupon.data).lounges.type==='all'||JSON.parse(this.coupon.data).tickets.type==='all'?'Getränke, ':'Getränke ':''}}
      {{JSON.parse(this.coupon.data).lounges.type==='all'?JSON.parse(this.coupon.data).tickets.type==='all'?'Lounges, ':'Lounges ':''}}
      {{JSON.parse(this.coupon.data).tickets.type==='all'?'Tickets ':''}}
    </div>
    <div class="col-sp-4 row-sp-12">
      <div class="qrCodeImg">
        <img ref="qrCodeMini" v-show="!qrCodeBool" @click="qrCodeBool = !qrCodeBool">
      </div>
    </div>
    <div v-show="qrCodeBool" class="col-sp-12 row-sp-12" @click="qrCodeBool = !qrCodeBool">
      <div style="display: flex;justify-content: center">
        <img ref="qrCode"  class="pad-16 br-10 bgcolor-white">
      </div>
    </div>
    <div class="col-sp-12 row-sp-12">
      <button v-if="user.coupons.id !== coupon.id" class="button bgcolor-blue" @click="pushToCart(coupon)">Zum Warenkorb hinzufügen</button>
      <p v-else>Wurde zum Warenkorb hinzugefügt</p>
    </div>
  </article>
</template>

<script>
import {configStore} from "@/store/configStore";
import {dateToString, stringToDate} from "@/modules/main";
import Qrious from "qrious";
import {userStore} from "@/store/userStore";

export default {
  name: "CouponElement",
  props: ["coupon"],
  setup(){
    const config = configStore()
    const user = userStore()

    return {config,user}
  },
  data(){
    return{
      qrCodeBool: false
    }
  },
  computed: {
    validUntil(){
      return dateToString(stringToDate(this.coupon.validUntil))
    }
  },
  mounted() {
    this.config.loading = false
    this.loadQr()
  },
  methods:{
    loadQr(){
      this.$nextTick(() => {
        new Qrious({
          element: this.$refs['qrCode'],
          size: 300,
          value: this.coupon.code,
          background: '#ffffff',
          foreground: '#000000',
          level: 'H',
        })
        new Qrious({
          element: this.$refs['qrCodeMini'],
          size: 80,
          value: this.coupon.code,
          background: '#ffffff',
          foreground: '#000000',
          level: 'H',
        })
      })
    },
    pushToCart(coupon){
      // Ist der Coupon bereits im UserStore ?
      if (!!this.user.coupons.id === coupon.id)return
      //Füge den Coupon den UserStore hinzu
      this.user.coupons=coupon
    },
    copyToClipboard() {
      //Erstellt eine Textare packt den Code da rein Kopiert diesen raus und löscht die Textarea wieder
      const textArea = document.createElement("textarea");
      textArea.value = this.coupon.code.toUpperCase();
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
      this.coupon.copy=true
      setTimeout(()=>{this.coupon.copy=false},2000)
    }
  }
}
</script>

<style>
.coupon{
  position: relative;
  margin-bottom: 32px;
  background-color: var(--second-bg-color);
  color: #ffffff;
  border-radius: 10px;
  padding: 16px;
  border: 2px solid var(--second-bg-color);
  align-items: center;
  column-gap: 6px;
}
.coupon.promotion{
  border: 2px solid #2068ff;
}
.coupon .imageBox{
  position: relative;
  vertical-align: middle;
}
.coupon .imageBox img{
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  vertical-align: middle;
}

.coupon .qrCodeImg img{
  background-color: white;
  padding: 10px;
  border-radius: 12px;
}
.coupon .textBox{
  position: relative;
  padding: 16px 0;
}
.coupon .textBox span{

  font-weight: 400;
}
.promotionTag{
  font-size: 0.8em;
  color: rgba(255,255,255,0.6);
  margin-bottom: 8px;
}
</style>
<template>
  <a style="text-decoration: none; color: white;" target="_blank" :href="config.projectURL + 'checkout/getFile?type=' + fileType + '&file=' + file.encrypted">

    <div style="display: flex; flex-wrap: wrap; column-gap: 100%; justify-content: center;">
      <img src="/assets/images/icons/wallet.png" style="width: 100%; max-width: 160px; margin-bottom: 12px;">
      <span style="color: white;">#{{ file.name.split('.')[0] }}</span>
    </div>
  </a>
</template>

<script>
import {configStore} from "@/store/configStore";

export default {
  name: "addToWallet",
  props: {
    file: {
      type: Object,
      required: true
    },
    fileType: {
      type: String,
      required: true
    }
  },
  setup() {
    const config = configStore()

    return {config}
  },
}
</script>

<style scoped>

</style>
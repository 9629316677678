<template>
  <section id="Scanner" v-show="scan">
    <div class="videoContainer">
      <video ref="scanner" playsinline="true" />
      <transition name="error" id="error">
        <div class="t-center error" v-if="error">
          <div class="pad-24" />
          <img src="assets/images/icons/error-white.svg" class="width-100">
          <h5 style="font-size: 1.5em; margin-bottom: 16px; margin-top: 8px">
            {{ error }}
          </h5>
          <button @click="error = false" class="bgcolor-white color-black button">
            Okay
          </button>
        </div>
      </transition>
    </div>
    <button type="button" @click="cancelScan" style="width: 100%;">
      Abbrechen
    </button>
  </section>
  <button v-if="!scan" type="button" @click="startScanner" class="button">
    Code Scannen
  </button>
</template>

<script>
import QrScanner from 'qr-scanner';

export default {
  name: "scanner",
  emits: ['scan'],
  data() {
    return {
      qrScanner: null,
      scan: false,
      error: false,
      scannerTools: false
    }
  },
  watch: {
    eventId(){
      this.startScanner()
    }
  },
  mounted() {
    this.initScanner()
  },
  beforeUnmount() {
    try {
      this.qrScanner.stop()
      this.qrScanner.destroy();
      this.qrScanner = null;
    }catch (e) {}
  },
  methods:{
    async initScanner(){
      const _this = this
      return new Promise(resolve => {
        _this.qrScanner = new QrScanner(
            _this.$refs.scanner,
            _this.scanned,
            {
              highlightScanRegion: true,
              highlightCodeOutline: true
            })
        _this.qrScanner.setInversionMode('both');
        resolve()
      })
    },
    startScanner(){
      this.scan = true
      console.log(this.qrScanner)
      this.qrScanner.start()
    },
    cancelScan(){
      const _this = this
      setTimeout(() => {
        _this.stopScanner()
      }, 1000)
    },
    stopScanner(){
      try {
        this.qrScanner.stop()
        this.scan = false
      }catch (e) {}
    },
    scanned(r){
      this.$emit('scan', r)
      this.cancelScan()
    },
  },
}
</script>

<style scoped>
#Scanner h1{
  font-size: 2em;
}
#Scanner h3{
  font-size: 1.2em;
}
main#ticketScanner{
  padding-bottom: 80px;
}
section#Scanner video{
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
#ticketScanner .field select{
  display: block;
  width: 100%;
  border-radius: 10px;
  border: none;
  background-color: rgba(255,255,255,1);
  padding: 12px;
  margin-bottom: 8px;
}
#Scanner{
  position: relative;
}
#Scanner #ticketChecked{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #ffffff;
  background-color: #00cc66;
  border-radius: 10px;
  z-index: 9;
}
#Scanner #error{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #ffffff;
  background-color: #BD081C;
  border-radius: 10px;
  z-index: 9;
}

button {
  padding: 8px;
  /*width: 80%;*/
  max-width: 300px;
  border-radius: 8px;
  border: none;
  background-color: #1E1E1E;
  color: white;
}

.scanned-enter-active,
.scanned-leave-active{
  transition: opacity 1s ease;
}
.scanned-enter-from,
.scanned-leave-to {
  opacity: 0;
}

.error-enter-active{
  animation: bounce-in 0.5s;
}
.error-leave-active {
  animation: bounce-in 0.5s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
table{
}
table thead{
  background-color: rgba(0,0,0,0.05);
  font-weight: bold;
}
table thead tr td{
  padding: 4px;
}
</style>
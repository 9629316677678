<template>
  <main class="content-500" id="myevents">
    <section id="savedEvents">
      <div class="title">
        <h1>Meine Events</h1>
        <h2>Von dir gespeichert</h2>
      </div>
      <events v-for="event in events" :event="event" :key="event.id" />
      <div class="pad-16 t-center color-white" v-if="events.length == 0">
        <p>Du hast aktuell zu keinem Event zugesagt.</p>
      </div>
    </section>
    <section id="ownEvents">
      <div class="title">
        <div class="pad-16 t-center">
          <router-link to="/home" class="button bgcolor-blue color-white">
            Weitere Events finden
          </router-link>
        </div>
      </div>
    </section>
  </main>
</template>

<script>


import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import events from "@/components/home/event";

export default {
  name: "MyEvents",
  title: "MyEvents",
  components: {
    'events': events,
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      events: [],
    }
  },
  mounted() {
    this.config.loading = false
    this.getmyEventItems()
  },
  methods: {
    getmyEventItems(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('myevents/getmyEventItems', fD)
          .then((response) => {
            console.log(response)
            if(response.status === 250){
              _this.events = response.data
            }
          })
    },
  }
}
</script>

<style>
#myevents #savedEvents .title h1{
  margin-top: 16px;
  font-size: 2em;
  font-weight: 800;
  text-transform: uppercase;
  text-align: center;
}
#myevents #savedEvents .title h2{
  font-size: 1.1em;
  font-weight: 200;
  color: rgba(255,255,255,0.8);
  text-align: center;
  margin-bottom: 32px;
}
#myevents #savedEvents{
  padding: 16px;
}
#myevents #ownEvents .title h3{
  margin-top: 16px;
  font-size: 1.8em;
  font-weight: 800;
  text-transform: uppercase;
  text-align: center;
}
#myevents #ownEvents .title h4{
  font-size: 1em;
  font-weight: 200;
  color: rgba(255,255,255,0.8);
  text-align: center;
}

</style>
<template>
  <input type="file" ref="file" accept="image/*" capture="filesystem" style="display: none;" @change="crop">
  <div @click="$refs.file.click()" v-if="!src && !init">
    <slot>
      <button class="button bgcolor-blue color-white tiny">
        Eigene Datei hochladen
      </button>
    </slot>
  </div>
  <div style="overflow: hidden;">
    <vue-cropper ref="cropper" :src="src" alt="Source Image" v-if="src" @cropend="getBlob" @ready="getBlob" />
  </div>
</template>

<script>
import {ref} from "vue";
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';

export default {
  name: "CropperUpload",
  components: { VueCropper},
  props: {
    ratio: {
      type: Number,
      default: 0
    },
    init: {
      type: Boolean,
      default: false
    }
  },
  emits: ['cropped'],
  setup(){
    const file = ref(null)
    let src = ref(null)
    const crop = async() => {
      src.value = URL.createObjectURL(file.value.files[0])
    }
    return {crop,  file, src}
  },
  watch: {
    src(){
      if(this.src && this.ratio){
        this.$nextTick(() => {
          this.$refs.cropper.setAspectRatio(this.ratio)
        })
      }
    },
  },
  mounted() {
    let media = navigator.userAgent.match(/(iPad|iPhone|iPod)/g) ? 'media' : 'webcam';
    let fileInput = this.$refs.file;
    if (fileInput) {
      fileInput.setAttribute('capture', 'filesystem');
      fileInput.removeAttribute('capture');
    }
    if(this.init) fileInput.click();
  },
  methods: {
    getBlob(){
      const _this = this
      const canvas = this.$refs.cropper.getCroppedCanvas()
      canvas.toBlob(blob => {
        _this.$emit("cropped", blob)
      })
    }
  }
}
</script>

<style>
  .cropper-container.cropper-bg{
    max-height: 40vh;
  }
</style>
<template>
  <main id="create" class="content-500">
    <section class="t-center pad-16">
      <h1>Erstelle</h1>
      <h2>dein nächstes Event</h2>
    </section>
    <section id="eventTemplates" class="pad-16">
      <h3>Eventvorlagen</h3>
      <div class="slider-wrapper">
        <div class="slider">
          <article>
            <img src="assets/images/events/party.jpeg">
            <div class="content">
              <h4>Houseparty</h4>
            </div>
          </article>
          <article>
            <img src="assets/images/events/party.jpeg">
            <div class="content">
              <h4>Houseparty</h4>
            </div>
          </article>
        </div>
      </div>
    </section>
    <section id="createForm" class="pad-16">
      <form @submit.prevent="createEvent">
        <div class="field">
          <label>Titelbild <span>PNG oder JPEG (1200 x 640px)</span></label>
          <input type="file" accept="image/*" style="display: none;">
          <button class="bgcolor-second button fluid color-white">
            Datei auswählen
          </button>
        </div>
        <div class="field">
          <label>Eventname <span>(max. 20 Zeichen)</span></label>
          <input type="text" maxlength="20" :placeholder="'Houseparty bei ' +user.firstname" v-model="title">
        </div>
        <div class="field">
          <label>Beschreibung</label>
          <textarea rows="5" :placeholder="'Die Houseparty bei ' +user.firstname+' steht an. Denkt bitte an folgende Dinge:'" v-model="description" />
        </div>
        <div class="field">
          <label>Event Location</label>
          <input type="text" :placeholder="'Bei ' +user.firstname+' zuhause'" v-model="location">
        </div>
        <div class="field">
          <label>Event Zeitpunkt</label>
          <input type="datetime-local" v-model="date">
        </div>
        <div class="field">
          <label>Themen</label>
          <input type="text" readonly value="🥳 Beerpong">
          <div class="t-center pad-8">
            <button class="tiny button color-white bgcolor-second">
              Thema hinzufügen
            </button>
          </div>
        </div>
        <div class="field">
          <label>Zu- oder Absagen bis zum</label>
          <input type="datetime-local" v-model="signdate">
        </div>
        <div class="t-center">
          <button type="submit" class="button bgcolor-orange color-white">
            Event erstellen
          </button>
        </div>
      </form>
    </section>
  </main>
</template>

<script>


import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";

export default {
  name: "Create",
  title: "Create",
  components: {
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
    }
  },
  mounted() {
    this.config.loading = false
  },
  methods: {

  }
}
</script>

<style>
main#create{
  padding-bottom: 80px;
  padding-top: 0!important;
}
main#create h1{
  margin-top: 64px;
  font-size: 2em;
  font-weight: 800;
  text-transform: uppercase;
}
main#create h2{
  font-size: 1.1em;
  font-weight: 200;
  color: rgba(255,255,255,0.8);
}
main#create #eventTemplates h3{
  font-size: 1.1em;
  font-weight: 200;
  color: rgba(255,255,255,0.8);
  margin-bottom: 8px;
  padding-left: 8px;
}
main#create #eventTemplates .slider-wrapper{
  overflow-x: scroll;
}
main#create #eventTemplates .slider{
  width: max-content;
}
main#create #eventTemplates article{
  padding: 8px;
  width: 65vw;
  max-width: 350px;
  text-align: center;
  position: relative;
  display: inline-block;
}
main#create #eventTemplates article img{
  max-width: 100%;
  border: 2px solid #ffffff;
  border-radius: 10px;
}
main#create #eventTemplates article .content{
  position: absolute;
  top: 10px;
  left: 10px;
  border-radius: 10px;
  height: calc(100% - 24px);
  width: calc(100% - 16px);
  background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
}
main#create #eventTemplates article .content h4{
  position: absolute;
  bottom: 8px;
  text-align: center;
  width: calc(100% - 16px);
  font-size: 1.2em;
  text-transform: uppercase;
}
main#create #createForm .field{
  margin-bottom: 24px;
}
main#create #createForm label{
  color: #F2A43A;
  font-weight: 600;
}
main#create #createForm label span{
  color: rgba(255,255,255,0.6);
  font-weight: 200;
  font-size: 0.8em;
}
main#create #createForm input,
main#create #createForm textarea{
  width: calc(100% - 16px);
  padding: 8px;
  border-radius: 8px;
  border: none;
  background-color: var(--second-bg-color);
  color: #ffffff;
  margin-top: 4px;
  max-width: calc(100% - 16px);
}
</style>
import { authStore } from '@/store/authStore'
import { configStore } from '@/store/configStore'
import axios from 'axios'

export function aSyncData (url, postData, options) {
    return new Promise((resolve, reject) => {
        const auth = authStore()
        const config = configStore()

        const fD = new FormData()
        fD.append('sessionID', auth.sessionID)

        for (const key in postData) {
            fD.append(key, typeof postData[key] === 'object' ? JSON.stringify(postData[key]) : postData[key])
        }

        axios.post(config.projectURL + url, fD, options)
            .then(r => {
                resolve(r)
            })
            .catch(e => {
                reject(e)
            })
    })
}

export function stringToDate(dateString){
    try {
        let arr = dateString.split(/[- :]/)
        return arr.length === 3 ? new Date(parseInt(arr[0]), parseInt(arr[1])-1, parseInt(arr[2])) : new Date(parseInt(arr[0]), parseInt(arr[1])-1, parseInt(arr[2]), parseInt(arr[3]), parseInt(arr[4]), parseInt(arr[5]))
    }catch (e) {
        return null
    }
}

export function dateToString(date){
    try {
        return String(date.getHours()).padStart(2, '0') + ':' + String(date.getMinutes()).padStart(2, '0') + ' ' + String(date.getDate()).padStart(2, '0') + '.' + String(date.getMonth() +1).padStart(2, '0') + '.' + date.getFullYear()
    }catch (e) {
        return null
    }
}

/**
 *
 * @param str
 * @returns {string}
 */
export function nl2br(str){
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br/>$2');
}
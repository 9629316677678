<template>
  <article class="pad-4 friendrequestItem">
    <div class="grid-12">
      <div class="col-sp-3 row-sp-12">
        <img :src="'assets/images/users/'+friendsRequestItems.userimg" class="width-28 circular">
      </div>
      <div class="col-sp-9 row-sp-12 t-left">
        <h6>{{ friendsRequestItems.firstname }}</h6>
      </div>
      <div class="col-sp-12 row-sp-12 t-right" style="padding-top: 4px; padding-bottom: 16px;">
        <button class="tiny button bgcolor-black color-white" @click="deleteFriend(friendsRequestItems.userid);">
          Ablehnen
        </button>
        <button style="margin-left: 8px;" class="tiny button bgcolor-blue color-white" @click="acceptFriend(friendsRequestItems.userid);">
          Annehmen
        </button>
      </div>
    </div>
  </article>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";

export default {
  name: "FriendsRequestItems",
  props: ["friendsRequestItems"],
  emits: ['update'],
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
    }
  },
  mounted() {
    this.config.loading = false
  },
  methods: {
    deleteFriend(userid){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('friendId', userid)
      this.axios.post('profil/deleteFriend', fD)
          .then((response) => {
            if(response.status === 250){
              this.$emit('update');
            }
          })
    },
    acceptFriend(userid){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('friendId', userid)
      this.axios.post('profil/acceptFriend', fD)
          .then((response) => {
            if(response.status === 250){
              this.$emit('update');
            }
          })
    }
  }
}
</script>

<style scoped>

.friendrequestItem h6{
  font-weight: 600;
  padding-top: 8px;
}
.friendrequestItem button{
  font-size: 0.7em;
  text-transform: none;
}
</style>
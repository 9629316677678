<template>
  <div style="margin-top: 200px">
    <tip :amount="20" payment-intent-hash="16e1266ce36cde793f0c1c0b36b58845" />
  </div>
</template>

<script>
import tip from '@/components/payment/tip'

export default {
  name: "test",
  components: {tip}
}
</script>

<style scoped>

</style>
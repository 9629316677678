<template>
  <article class="pad-4 grid-12">
    <div class="col-sp-10 row-sp-12 t-left">
      <h6>{{ citySelectionItems.name }}</h6>
    </div>
    <div class="col-sp-2 row-sp-12 t-right">
      <button class="button tiny bgcolor-blue color-white" style="padding: 2px 4px;" @click="deleteCity(citySelectionItems.myCityId)">
        <img src="assets/images/icons/delete-white.svg" class="width-20">
      </button>
    </div>
  </article>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";

export default {
  name: "CitySelectionItems",
  props: ["citySelectionItems"],
  emits: ['update'],
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
    }
  },
  mounted() {
    this.config.loading = false
  },
  methods: {
    deleteCity(myCityId){
      console.log('Test'+myCityId)
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('id', myCityId)
      this.axios.post('profil/removeMyCity', fD)
          .then((response) => {
            console.log(response)
            if(response.status === 250){
              this.$emit('update')
            }

          })
    }

  }
}
</script>

<style scoped>

</style>
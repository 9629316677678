<template>
  <div class="small" v-if="size === 'small'">
    <img class="small" src="/assets/images/icons/circle.gif">
  </div>
  <div class="big" v-else>
    <img class="big" src="/assets/images/icons/circle.gif">
  </div>
</template>

<script>
export default {
  name: "loader",
  props: ['size']
}
</script>

<style scoped>
div.small{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(121, 117, 117, 0.8);
}
img.small{
  position: absolute;
  top: 50%;
  left: 50%;
  height: auto !important;
  transform: translate(-50%, -50%);
  opacity: 0.9;
  max-width: 100px;
}

div.big{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
img.big{
  max-width: 200px;
}
</style>